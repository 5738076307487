import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import CanvasJSReact from './assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

var dps = [];   //dataPoints.
var xVal = dps.length + 1;
var yVal = 15;
var updateInterval = 1000;

export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
      super(props);
      this.setUrl = this.setUrl.bind(this);
      this.getResult = this.getResult.bind(this);
      this.setModul1 = this.setModul1.bind(this);
      this.setModul2 = this.setModul2.bind(this);
      this.state = {
          loading: false,
          url: "",
          urls: "",
          result: "",
          modul1: 1,
          modul2: 1,
          buttonName: "Анализ"
     };
  }

    setUrl(str) {   
        this.setState({ urls: str });
    }
    setModul1() {
        var t = this.state.modul1;
        t = t === 0 ? 1 : 0;
        this.setState({ modul1: t });
    }
    setModul2() {
        var t = this.state.modul2;
        t = t === 0 ? 1 : 0;
        this.setState({ modul2: t });
    }
    async getResult() {
        this.setState({
            buttonName: "Секундочку",
            loading: true
        });
        const token = await authService.getAccessToken();

        fetch('api/data/result', {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ text: this.state.urls })
        }).then(response => response.json())
            .then(data => {      
                this.setState({
                    result: data,
                    buttonName: "Анализ",
                    loading: false
                });
            });
    }
    render() {     
        var result = this.state.result === "" ? <div></div> :
            <div>
                <div style={{ marginTop: "25px" }} className="row"><div className="col-md-3"><strong>Даты:</strong></div><div className="col-md-2">{this.state.result.dates.map(x => <div><u>{x}:</u></div>)}</div></div>
                <div className="row"><div className="col-md-3"><strong>Локации:</strong></div><div className="col-md-2" style={{ marginTop: "25px" }}>{this.state.result.locations.map(x => <div><u>{x}:</u></div>)}</div></div>
                <div className="row"><div className="col-md-3"><strong>Имена/названия:</strong></div><div className="col-md-2" style={{ marginTop: "25px" }}>{this.state.result.names.map(x => <div><u>{x}:</u></div>)}</div></div>
                <div className="row"><div className="col-md-3"><strong>Объекты:</strong></div><div className="col-md-2" style={{ marginTop: "25px" }}>{this.state.result.objects.map(x => <div><u>{x}:</u></div>)}</div></div>
                <div className="row"><div className="col-md-3"><strong>Описание объектов/контекст:</strong></div><div className="col-md-2" style={{ marginTop: "25px" }}>{Object.keys(this.state.result.obj_descriptions).map(x => <div><u>{x}:</u> <div style={{ marginLeft: "20px" }}>{this.state.result.obj_descriptions[x].map(y => <div>{y}</div>)}</div></div>)}</div></div>
            </div>;
        var modul1 = this.state.modul1 === 0 ? <div>
            <a onClick={this.setModul1}>Общая классификация</a>
            <button style={{ marginLeft: "10px" }} className="btn btn-light" onClick={this.setModul1}> ↓ </button>
        </div> : <div>
                <div className="row">
                <h2>Общая классификация</h2>
                    <button style={{ marginLeft: "10px" }} className="btn btn-light" onClick={this.setModul1}> ↑ </button>
                </div>
            <div className="row">
                <div className="col-md-12">
                    <label>Введите url</label>
                    <textarea type="text" placeholder="Список страниц для классификации" style={{ height: "150px" }} className="form-control" name="url" value={this.state.urls} onChange={(e) => this.setUrl(e.target.value)}></textarea>
                    {/*<input type="text" className="form-control" name="start" value={this.state.url} onChange={(e) => this.setStart(e.target.value)}></input>*/}
                </div>               
                </div>
                <div className="col-md-2" style={{ marginTop: "33px" }}>
                    <button className="btn btn-primary" onClick={this.getResult}>Определить</button>
                </div>
                <div style={{ marginTop: "50px" }}>{this.state.result}</div>
        </div>;

        var modul2 = this.state.modul2 === 0 ? <div><a onClick={this.setModul2}>Выделение ключевых объектов</a><button style={{ marginLeft: "10px" }} className="btn btn-light" onClick={this.setModul2}> ↓ </button></div> : <div>
            <div className="row">
                <h2>Выделение ключевых объектов</h2>
                <button style={{ marginLeft: "10px" }} className="btn btn-light" onClick={this.setModul2}> ↑ </button>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <label>Введите описание запроса</label>
                    <textarea type="text" placeholder="Описание запроса" style={{ height: "150px" }} className="form-control" name="url" value={this.state.urls} onChange={(e) => this.setUrl(e.target.value)}></textarea>
                    {/*<input type="text" className="form-control" name="start" value={this.state.url} onChange={(e) => this.setStart(e.target.value)}></input>*/}
                </div>              
            </div>
            <div className="col-md-2" style={{ marginTop: "33px" }}>
                <button className="btn btn-primary" disabled={this.state.loading} onClick={this.getResult}>{this.state.buttonName}</button>
            </div>
            {result}
        </div>;

        return (
            <div>
                <h1 className="text-center">Выделение ключевых объектов</h1>
                <div style={{ marginTop: "50px" }} className="row">
                    {/*<div className="col-md-6"> {modul1} </div>*/}
                    <div className="col-md-12">{modul2} </div>
                </div>
                <div style={{ marginTop: "400px" }}></div>
            </div>
        );
    }

  async populateWeatherData() {
    const token = await authService.getAccessToken();
    const response = await fetch('/data/result', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
                    
